export const RESULTS_DATA_ATTRIBUTE_IDS = {
  expandSubRow: 'ProductDatabase.Result.Variants.ExpandSubRow',
  showAllVariants: 'ProductDatabase.Result.Variants.showAll',
  HorizontalScroll: 'ProductDatabase.Results.HorizontalScroll',
  VerticalScroll: 'ProductDatabase.Results.VerticalScroll',
  Buttons: {
    AddToTracker: 'ProductDatabase.Results.Buttons.AddToTracker',
    RefreshProduct: 'ProductDatabase.Results.Buttons.RefreshProduct',
    DownloadCSV: 'ProductDatabase.Results.Buttons.DownloadCSV',
    CustomizeColumns: 'ProductDatabase.Results.Buttons.CustomizeColumns'
  },
  MoreActionsDropdown: {
    AddToTracker: 'ProductDatabase.Results.MoreActionsDropdown.AddToTracker',
    BulkAddToTracker:
      'ProductDatabase.Results.MoreActionsDropdown.BulkAddToTracker',
    RefreshProduct:
      'ProductDatabase.Results.MoreActionsDropdown.RefreshProduct',
    RefreshProductOnly:
      'ProductDatabase.Results.MoreActionsDropdown.RefreshProductOnly'
  },
  SortColumns: {
    ProductTitle: 'ProductDatabase.Results.SortColumns.ProductTitle',
    VariantDifferences:
      'ProductDatabase.Results.SortColumns.VariantDifferences',
    Category: 'ProductDatabase.Results.SortColumns.Category',
    MonthlyRevenue: 'ProductDatabase.Results.SortColumns.MonthlyRevenue',
    MonthlySales: 'ProductDatabase.Results.SortColumns.MonthlySales',
    Price: 'ProductDatabase.Results.SortColumns.Price',
    Rank: 'ProductDatabase.Results.SortColumns.Rank',
    Reviews: 'ProductDatabase.Results.SortColumns.Reviews',
    LQS: 'ProductDatabase.Results.SortColumns.LQS',
    Sellers: 'ProductDatabase.Results.SortColumns.Sellers',
    DateFirstAvailable:
      'ProductDatabase.Results.SortColumns.DateFirstAvailable',
    Rating: 'ProductDatabase.Results.SortColumns.Rating',
    Dimensions: 'ProductDatabase.Results.SortColumns.Dimensions',
    Weight: 'ProductDatabase.Results.SortColumns.Weight'
  }
}
