export const FILTERS_DATA_ATTRIBUTE_IDS = {
  ColumnsDropdown: 'InventoryManager.Filters.ColumnsDropdown',
  HistoryDropdown: {
    FifteenDays: 'InventoryManager.Filters.HistoryDropdown.15days',
    ThirthyDays: 'InventoryManager.Filters.HistoryDropdown.30days',
    SixtyDays: 'InventoryManager.Filters.HistoryDropdown.60days',
    NinetyDays: 'InventoryManager.Filters.HistoryDropdown.90days',
    HundredAndTwentyDays: 'InventoryManager.Filters.HistoryDropdown.120days'
  },
  InventoryStatusDropdown: {
    Overstocked: 'InventoryManager.Filters.InventoryStatusDropdown.Overstocked',
    InStock: 'InventoryManager.Filters.InventoryStatusDropdown.InStock',
    ReorderSoon: 'InventoryManager.Filters.InventoryStatusDropdown.ReorderSoon',
    OrderNow: 'InventoryManager.Filters.InventoryStatusDropdown.OrderNow',
    NoStatus: 'InventoryManager.Filters.InventoryStatusDropdown.NoStatus'
  }
}
