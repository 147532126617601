export const TABLE_DATA_ATTRIBUTE_IDS = {
  Headers: {
    SortByAsin: 'InventoryManager.Table.Headers.SortByAsin',
    SortByAvgDailySales: 'InventoryManager.Table.Headers.SortByAvgDailySales',
    SortByAvgProfitPerUnit:
      'InventoryManager.Table.Headers.SortByAvgProfitPerUnit',
    SortByDateToReorder: 'InventoryManager.Table.Headers.SortByDateToReorder',
    SortByDaysRemaining: 'InventoryManager.Table.Headers.SortByDaysRemaining',
    SortByInboundLevel: 'InventoryManager.Table.Headers.SortByInboundLevel',
    SortByInventoryLevel: 'InventoryManager.Table.Headers.SortByInventoryLevel',
    SortByInventoryLevelCost:
      'InventoryManager.Table.Headers.SortByInventoryLevelCost',
    SortByInventoryLevelProfit:
      'InventoryManager.Table.Headers.SortByInventoryLevelProfit',
    SortByInventoryLevelRevenue:
      'InventoryManager.Table.Headers.SortByInventoryLevelRevenue',
    SortByInventoryStatus:
      'InventoryManager.Table.Headers.SortByInventoryStatus',
    SortByLeadTime: 'InventoryManager.Table.Headers.SortByLeadTime',
    SortByProductTitle: 'InventoryManager.Table.Headers.SortByProductTitle',
    SortByReorderCost: 'InventoryManager.Table.Headers.SortByReorderCost',
    SortByReorderDaysOfSupply:
      'InventoryManager.Table.Headers.SortByReorderDaysOfSupply',
    SortByReorderProfit: 'InventoryManager.Table.Headers.SortByReorderProfit',
    SortByReorderQuantity:
      'InventoryManager.Table.Headers.SortByReorderQuantity',
    SortBySku: 'InventoryManager.Table.Headers.SortBySku'
  }
}
