export const SEARCH_DATA_ATTRIBUTE_IDS = {
  MinMaxFilters: {
    avgUnitsSold: 'OpportunityFinder.MinMaxFilter.avgUnitsSold',
    avgPrice: 'OpportunityFinder.MinMaxFilter.avgPrice',
    estimatedExactSearchVolume:
      'OpportunityFinder.MinMaxFilter.estimatedExactSearchVolume',
    monthlyTrend: 'OpportunityFinder.MinMaxFilter.monthlyTrend',
    quarterlyTrend: 'OpportunityFinder.MinMaxFilter.quarterlyTrend',
    opportunityScore: 'OpportunityFinder.MinMaxFilter.opportunityScore',
    competition: 'OpportunityFinder.MinMaxFilter.competition',
    seasonality: 'OpportunityFinder.MinMaxFilter.seasonality'
  },
  Marketplaces: {
    US: 'OpportunityFinder.Marketplaces.US',
    CA: 'OpportunityFinder.Marketplaces.CA',
    MX: 'OpportunityFinder.Marketplaces.MX',
    DE: 'OpportunityFinder.Marketplaces.DE',
    ES: 'OpportunityFinder.Marketplaces.ES',
    FR: 'OpportunityFinder.Marketplaces.FR',
    IT: 'OpportunityFinder.Marketplaces.IT',
    UK: 'OpportunityFinder.Marketplaces.UK',
    JP: 'OpportunityFinder.Marketplaces.JP',
    IN: 'OpportunityFinder.Marketplaces.IN'
  }
}
