export const ProfitOverviewDataIds = {
  Products: {
    DownloadCSV: 'ProfitOverview.Products.DownloadCSV',
    ProductsTab: 'ProfitOverview.Products.ProductsTab',
    ColumnSorting: 'ProfitOverview.Products.ColumnSorting',
    RowClick: 'ProfitOverview.Products.RowClick'
  },
  ProductView: {
    Thumbnail: 'ProfitOverview.ProductView.Thumbnail'
  },
  ConnectToAmazon: {
    ConnectGetAccessButton:
      'ProfitOverview.ConnectToAmazon.ConnectGetAccessButton',
    UpdatePlanGetAccessButton:
      'ProfitOverview.ConnectToAmazon.UpdatePlanGetAccessButton'
  }
}
