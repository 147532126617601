export const SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS = {
  Buttons: {
    SegmentYAxis: 'MarketInsights.SegmentGraph.Buttons.YAxis',
    BrandsYAxis: 'MarketInsights.BrandsGraph.Buttons.YAxis',
    LineChart: 'MarketInsights.BrandsGraph.Buttons.LineChart',
    StackedArea: 'MarketInsights.BrandsGraph.Buttons.StackedArea',
    PercentArea: 'MarketInsights.BrandsGraph.Buttons.PercentArea',
    Treemap: 'MarketInsights.BrandsGraph.Buttons.Treemap',
    Revenue: 'MarketInsights.BrandsTable.Buttons.Revenue',
    UnitSales: 'MarketInsights.BrandsTable.Buttons.UnitSales',
    SortByName: 'MarketInsights.BrandsGraph.Buttons.SortByName',
    SortByRevenue: 'MarketInsights.BrandsGraph.Buttons.SortByRevenue',
    Apply: 'MarketInsights.BrandsGraph.Buttons.Apply',
    ViewAll: 'MarketInsights.ProductsTable.Buttons.ViewAll',
    Asin: 'MarketInsights.ProductsTable.Buttons.Asin',
    BrandsGraphDownloadCsv: 'MarketInsights.BrandsGraph.Buttons.DownloadCsv',
    SegmentGraphDownloadCsv: 'MarketInsights.SegmentGraph.Buttons.DownloadCsv',
    BrandsTableDownloadCsv: 'MarketInsights.BrandsTable.Buttons.DownloadCsv',
    ProductTrendsDownloadCsv:
      'MarketInsights.ProductTrends.Buttons.DownloadCsv',
    ProductTrendsTreeGraphItem:
      'MarketInsights.ProductTrends.Buttons.TreeGraphClick',
    ColumnSelector: 'MarketInsights.ProductTrends.Buttons.ColumnSelector'
  },

  DateInterval: {
    SegmentGraphApply: 'MarketInsights.SegmentGraph.DateInterval.Apply',
    BrandsGraphApply: 'MarketInsights.BrandsGraph.DateInterval.Apply',
    BrandsTableApply: 'MarketInsights.BrandsTable.DateInterval.Apply',
    SegmentGraph6M: 'MarketInsights.SegmentGraph.DateInterval.6M',
    SegmentGraph1Y: 'MarketInsights.SegmentGraph.DateInterval.1Y',
    SegmentGraph2Y: 'MarketInsights.SegmentGraph.DateInterval.2Y',
    SegmentGraphMax: 'MarketInsights.SegmentGraph.DateInterval.Max',
    BrandsGraph6M: 'MarketInsights.BrandsGraph.DateInterval.6M',
    BrandsGraph1Y: 'MarketInsights.BrandsGraph.DateInterval.1Y',
    BrandsGraph2Y: 'MarketInsights.BrandsGraph.DateInterval.2Y',
    BrandsGraphMax: 'MarketInsights.BrandsGraph.DateInterval.Max',
    BrandsTable6M: 'MarketInsights.BrandsTable.DateInterval.6M',
    BrandsTable1Y: 'MarketInsights.BrandsTable.DateInterval.1Y',
    BrandsTable2Y: 'MarketInsights.BrandsTable.DateInterval.2Y',
    BrandsTableMax: 'MarketInsights.BrandsTable.DateInterval.Max',
    ProductTrends30D: 'MarketInsights.ProductTrends.DateInterval.30D',
    ProductTrends90D: 'MarketInsights.ProductTrends.DateInterval.90D',
    ProductTrends180D: 'MarketInsights.ProductTrends.DateInterval.180D'
  },

  UniversalDatePicker: {
    DateInterval: {
      LastMonth: 'MarketInsights.UniversalDatePicker.DateInterval.LastMonth',
      LastQuarter:
        'MarketInsights.UniversalDatePicker.DateInterval.LastQuarter',
      OneYear: 'MarketInsights.UniversalDatePicker.DateInterval.OneYear',
      TwoYears: 'MarketInsights.UniversalDatePicker.DateInterval.TwoYears',
      YearToDate: 'MarketInsights.UniversalDatePicker.DateInterval.YearToDate',
      Yesterday: 'MarketInsights.UniversalDatePicker.DateInterval.Yesterday',
      ThisWeek: 'MarketInsights.UniversalDatePicker.DateInterval.ThisWeek',
      LastSevenDays:
        'MarketInsights.UniversalDatePicker.DateInterval.LastSevenDays',
      LastWeek: 'MarketInsights.UniversalDatePicker.DateInterval.LastWeek',
      LastThirtyDays:
        'MarketInsights.UniversalDatePicker.DateInterval.LastThirtyDays',
      LastFourteenDays:
        'MarketInsights.UniversalDatePicker.DateInterval.LastFourteenDays',
      MTD: 'MarketInsights.UniversalDatePicker.DateInterval.MTD',
      QTD: 'MarketInsights.UniversalDatePicker.DateInterval.QTD',
      LastNinetyDays:
        'MarketInsights.UniversalDatePicker.DateInterval.LastNinetyDays',
      LastYear: 'MarketInsights.UniversalDatePicker.DateInterval.LastYear'
    }
  },

  CheckBox: {
    Brands: 'MarketInsights.SegmentGraph.CheckBox.Brands',
    Asins: 'MarketInsights.SegmentGraph.CheckBox.Asins',
    AvgReviews: 'MarketInsights.SegmentGraph.CheckBox.AvgReviews',
    MedianPrice: 'MarketInsights.SegmentGraph.CheckBox.MedianPrice',
    UnitSales: 'MarketInsights.SegmentGraph.CheckBox.UnitSales',
    Revenue: 'MarketInsights.SegmentGraph.CheckBox.Revenue',
    Revenue1P: 'MarketInsights.SegmentGraph.CheckBox.Revenue1P',
    Revenue3P: 'MarketInsights.SegmentGraph.CheckBox.Revenue3P',
    SumOthers: 'MarketInsights.BrandsGraph.CheckBox.SumOthers'
  },

  Dropdown: {
    Brand: 'MarketInsights.BrandsGraph.Dropdown.Brand',
    RowToggle: 'MarketInsights.BrandsTable.Dropdown.RowToggle',
    SegmentToggle: 'MarketInsights.BrandsTable.Dropdown.SegmentToggle',
    SummaryFor: 'MarketInsights.ScoreCard.Dropdown.SummaryFor',
    ViewMoreDetails: 'MarketInsights.ScoreCard.Dropdown.ViewMoreDetails',
    ThreePSellersBrand: 'MarketInsights.ThreePSeller.Dropdown.Brand'
  },

  Radio: {
    Asins: 'MarketInsights.BrandsGraph.Radio.Asins',
    AvgReviews: 'MarketInsights.BrandsGraph.Radio.AvgReviews',
    MedianPrice: 'MarketInsights.BrandsGraph.Radio.MedianPrice',
    UnitSales: 'MarketInsights.BrandsGraph.Radio.UnitSales',
    Revenue: 'MarketInsights.BrandsGraph.Radio.Revenue',
    Revenue1P: 'MarketInsights.BrandsGraph.Radio.Revenue1P',
    Revenue3P: 'MarketInsights.BrandsGraph.Radio.Revenue3P'
  },

  SortColumns: {
    Brand: 'MarketInsights.BrandsTable.SortColumns.Brand',
    Share: 'MarketInsights.BrandsTable.SortColumns.Share',
    Revenue: 'MarketInsights.BrandsTable.SortColumns.Revenue',
    Revenue1P: 'MarketInsights.BrandsTable.SortColumns.Revenue1P',
    Revenue3P: 'MarketInsights.BrandsTable.SortColumns.Revenue3P',
    UnitSales: 'MarketInsights.BrandsTable.SortColumns.UnitSales',
    UnitSales1P: 'MarketInsights.BrandsTable.SortColumns.UnitSales1P',
    UnitSales3P: 'MarketInsights.BrandsTable.SortColumns.UnitSales3P',
    Weight: 'MarketInsights.BrandsTable.SortColumns.Weight',
    Reviews: 'MarketInsights.BrandsTable.SortColumns.Reviews',
    SellerTypes: 'MarketInsights.BrandsTable.SortColumns.SellerTypes',
    AvgPrice: 'MarketInsights.BrandsTable.SortColumns.AvgPrice',
    Rating: 'MarketInsights.BrandsTable.SortColumns.Rating',
    Sellers: 'MarketInsights.BrandsTable.SortColumns.Sellers',
    Category: 'MarketInsights.BrandsTable.SortColumns.Category',
    SubCategories: 'MarketInsights.BrandsTable.SortColumns.SubCategories',
    BuyBoxOwner: 'MarketInsights.BrandsTable.SortColumns.BuyBoxOwner',
    SalesRank: 'MarketInsights.BrandsTable.SortColumns.SalesRank',
    Dimensions: 'MarketInsights.BrandsTable.SortColumns.Dimensions',
    DateFirstAvailable:
      'MarketInsights.BrandsTable.SortColumns.DateFirstAvailable'
  },

  ProductTrends: {
    TreeGraph: {
      Filters: {
        Top25: 'MarketInsights.ProductTrendsGraph.Filter.Top25',
        New: 'MarketInsights.ProductTrendsGraph.Filter.New',
        Mature: 'MarketInsights.ProductTrendsGraph.Filter.Mature'
      }
    }
  },

  Tabs: {
    ProductsAndBrands: 'MarketInsights.ShareAndTrends.Tabs.ProductsAndBrands',
    ThreePSellers: 'MarketInsights.ShareAndTrends.Tabs.ThreePSellers'
  }
}
