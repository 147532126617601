export const BUTTONS_DATA_ATTRIBUTE_IDS = {
  Filters: 'InventoryManager.Buttons.FiltersDropdown',
  ApplyFilters: 'InventoryManager.Buttons.ApplyFilters',
  CancelFilters: 'InventoryManager.Buttons.CancelFilters',
  ClearFilters: 'InventoryManager.Buttons.ClearFilters',
  Search: 'InventoryManager.Buttons.Search',
  InventoryStatusDropdown: 'InventoryManager.Buttons.InventoryStatusDropdown',
  ConnectToAmazon: {
    ConnectGetAccessButton:
      'InventoryManager.ConnectToAmazon.ConnectGetAccessButton',
    UpdatePlanGetAccessButton:
      'InventoryManager.ConnectToAmazon.UpdatePlanGetAccessButton'
  }
}
