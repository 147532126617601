export const INPUTS_DATA_ATTRIBUTE_IDS = {
  daysRemainingMin: 'InventoryManager.Inputs.DaysRemainingMin',
  daysRemainingMax: 'InventoryManager.Inputs.DaysRemainingMax',
  reorderQuantityMin: 'InventoryManager.Inputs.ReorderQuantityMin',
  reorderQuantityMax: 'InventoryManager.Inputs.ReorderQuantityMax',
  inventoryLevelMin: 'InventoryManager.Inputs.InventoryLevelMin',
  inventoryLevelMax: 'InventoryManager.Inputs.InventoryLevelMax',
  inboundLevelMin: 'InventoryManager.Inputs.InboundLevelMin',
  inboundLevelMax: 'InventoryManager.Inputs.InboundLevelMax',
  averageDailySalesMin: 'InventoryManager.Inputs.AverageDailySalesMin',
  averageDailySalesMax: 'InventoryManager.Inputs.AverageDailySalesMax'
}
