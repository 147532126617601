export const DEFAULT_LINKS = {
  productUpdates:
    'https://www.junglescout.com/resources/articles/changelog/?from=webapp-dashboard',
  amazonNews: 'https://www.junglescout.com/blog/',
  tooltipTuesday: 'https://my.demio.com/ref/aCgw9elPP4isY0pk',
  liveWebinar: 'https://www.youtube.com/c/Junglescout/videos',
  academy: 'https://members.junglescout.com/#/academy',
  searchForArticles: 'https://support.junglescout.com/',
  contactSupport: 'https://support.junglescout.com/hc/en-us/requests/new',
  downloadProductResearchChecklist:
    'https://www.junglescout.com/wp-content/uploads/2021/06/Jungle-Scout-Master-Checklist-Product-Research.pdf',
  downloadSupplierResearchChecklist:
    'https://www.junglescout.com/wp-content/uploads/2021/06/Jungle-Scout-Master-Checklist-Supplier-Research.pdf',
  downloadShippingLaunchChecklist:
    'https://www.junglescout.com/wp-content/uploads/2021/11/Jungle-Scout-Master-Checklist-Shipping-Launch.pdf',
  downloadTrackMasterChecklist:
    'https://www.junglescout.com/wp-content/uploads/2021/11/Jungle-Scout-Master-Checklist.pdf',
  downloadMasterChecklist:
    'https://www.junglescout.com/wp-content/uploads/2021/11/Jungle-Scout-Master-Checklist.pdf',
  downloadManagementChecklist:
    'https://www.junglescout.com/wp-content/uploads/2021/06/Jungle-Scout-Master-Checklist-Business-Management-Growth.pdf',
  amazonInventoryGuide:
    'https://www.junglescout.com/blog/amazon-inventory-management-guide-2/',
  browserExtension: '/#/academy/78',
  productDatabase: '/#/academy/7',
  opportunityFinder: '/#/academy/82',
  productTracker: '/#/academy/81',
  supplierTracker: '/#/academy/85',
  keywordScout: '/#/academy/86',
  keywordLists: '/#/academy/23',
  supplierDatabase: '/#/academy/84',
  listingBuilder: '/#/academy/87',
  rankTracker: '/#/academy/88',
  categoryTrends:
    'https://support.junglescout.com/hc/en-us/articles/1500005246661-Category-Trends-Feature-Overview',
  productSettings:
    'https://support.junglescout.com/hc/en-us/articles/360035714594-Product-Settings-Feature-Overview',
  productCosts:
    'https://support.junglescout.com/hc/en-us/articles/360036208253-Product-Costs-Entering-your-Cost-of-Goods-COGs-',
  profitOverview:
    'https://support.junglescout.com/hc/en-us/articles/360036200053-Sales-Analytics-Profit-Overview',
  inventoryManager: '/#/academy/94',
  reviewAutomation: '/#/academy/130',
  resources: 'https://www.junglescout.com/resources/',
  ppcCampaigns: '/#/advertising/analytics',
  supplierDbOverhaul: '/#/supplier',
  competitiveIntelligence: '/#/competitive-intelligence'
}

export const CHINESE_LINKS = {
  productUpdates: 'https://www.junglescout.cn/changelog/?from=webapp-dashboard',
  amazonNews: 'https://www.junglescout.cn/blog/?from=webapp-dashboard',
  tooltipTuesday: 'https://loy.h5.xeknow.com/s/1CjNNM/?from=webapp-dashboard',
  liveWebinar: 'https://www.junglescout.cn/webinar/',
  academy: 'https://members.junglescout.cn/#/academy',
  searchForArticles: 'https://docs.junglescout.cn/',
  contactSupport:
    'https://www.echatsoft.com/visitor/pc/chat.html?companyId=12084&echatTag=dashboard',
  downloadProductResearchChecklist:
    'https://js-cn-market-public-files.oss-cn-shenzhen.aliyuncs.com/download/Jungle-Scout-Master-Checklist-Product-Research_CN.pdf?from=dashboard-rps',
  downloadSupplierResearchChecklist:
    'https://js-cn-market-public-files.oss-cn-shenzhen.aliyuncs.com/download/Jungle-Scout-Master-Checklist-Supplier-Research_CN.pdf?from=dashboard-rps',
  downloadShippingLaunchChecklist:
    'https://js-cn-market-public-files.oss-cn-shenzhen.aliyuncs.com/download/Jungle-Scout-Master-Checklist-Shipping-Launch_CN.pdf?from=dashboard-rps',
  downloadTrackMasterChecklist:
    'https://js-cn-market-public-files.oss-cn-shenzhen.aliyuncs.com/download/Jungle-Scout-Master-Checklist-Business-Management-Growth_CN.pdf?from=dashboard-tpp',
  downloadMasterChecklist:
    'https://js-cn-market-public-files.oss-cn-shenzhen.aliyuncs.com/download/Jungle-Scout-Master-Checklist-Business-Management-Growth_CN.pdf?from=dashboard-oypp',
  downloadManagementChecklist:
    'https://js-cn-market-public-files.oss-cn-shenzhen.aliyuncs.com/download/Jungle-Scout-Master-Checklist-Business-Management-Growth_CN.pdf?from=dashboard-sybp',
  amazonInventoryGuide:
    'https://www.junglescout.cn/blog/40043/?from=webapp-dashboard',
  browserExtension: '/#/academy/161',
  productDatabase: '/#/academy/165',
  opportunityFinder: '/#/academy/167',
  productTracker: '/#/academy/166',
  supplierTracker: '/#/academy/169',
  salesEstimator: '/#/academy/164',
  keywordScout: '/#/academy/170',
  keywordLists: '/#/academy/171',
  supplierDatabase: '/#/academy/168',
  listingBuilder: '/#/academy/172',
  rankTracker: '/#/academy/173',
  listingGrader: '/#/academy/163',
  categoryTrends: '/#/academy/183',
  productSettings:
    'https://docs.junglescout.cn/js/%e7%ae%a1%e7%90%86%e4%ba%a7%e5%93%81-%e4%ba%a7%e5%93%81%e8%ae%be%e7%bd%ae%e5%8a%9f%e8%83%bd',
  productCosts: '/#/academy/181',
  otherTransactions: '/#/academy/179',
  profitOverview: '/#/academy/177',
  inventoryManager: '/#/academy/180',
  pLStatement: '/#/academy/178',
  reviewAutomation: '/#/academy/176'
}
