export const MWS_SYNC_DATA_ATTRIBUTE_IDS = {
  Buttons: {
    MwsModal: {
      Tutorial: 'Account.MwsSync.Buttons.MwsModal.Tutorial',
      ClickHerePricing: 'Account.MwsSync.Buttons.MwsModal.ClickHerePricing',
      GetStarted: 'Account.MwsSync.Buttons.MwsModal.GetStarted',
      Next: 'Account.MwsSync.Buttons.MwsModal.Next',
      SetupWizardNext: 'Account.MwsSync.Buttons.MwsModal.SetupWizardNext',
      Back: 'Account.MwsSync.Buttons.MwsModal.Back',
      SetupWizardGoBack: 'Account.MwsSync.Buttons.MwsModal.SetupWizardGoBack',
      LogInToSellerCentral:
        'Account.MwsSync.Buttons.MwsModal.LogInToSellerCentral',
      SetupWizardLogInToSellerCentral:
        'Account.MwsSync.Buttons.MwsModal.SetupWizardLogInToSellerCentral',
      PpcLogIn: 'Account.MwsSync.Buttons.MwsModal.PpcLogIn',
      SetupWizardPpcLogIn:
        'Account.MwsSync.Buttons.MwsModal.SetupWizardPpcLogIn',
      PpcHowToArticle: 'Account.MwsSync.Buttons.MwsModal.PpcHowToArticle',
      SetupWizardPpcHowToArticle:
        'Account.MwsSync.Buttons.MwsModal.SetupWizardPpcHowToArticle',
      PpcTryAgain: 'Account.MwsSync.Buttons.MwsModal.PpcTryAgain',
      SetupWizardPpcTryAgain:
        'Account.MwsSync.Buttons.MwsModal.SetupWizardPpcTryAgain',
      PpcNext: 'Account.MwsSync.Buttons.MwsModal.PpcNext',
      PpcSkipAndNext: 'Account.MwsSync.Buttons.MwsModal.PpcSkipAndNext',
      SetupWizardPpcSkipAndNext:
        'Account.MwsSync.Buttons.MwsModal.SetupWizardPpcSkipAndNext',
      AccountSetupNext: 'Account.MwsSync.Buttons.MwsModal.AccountSetupNext',
      SetupWizardAccountSetupNext:
        'Account.MwsSync.Buttons.MwsModal.SetupWizardAccountSetupNext',
      Finish: 'Account.MwsSync.Buttons.MwsModal.Finish',
      SetupWizardFinish: 'Account.MwsSync.Buttons.MwsModal.SetupWizardFinish',
      MwsSyncWatchTutorial:
        'Account.MwsSync.Buttons.MwsModal.MwsSyncWatchTutorial',
      HavingTroubleArticle:
        'Account.MwsSync.Buttons.MwsModal.HavingTroubleArticle',
      SetupWizardHavingTroubleArticle:
        'Account.MwsSync.Buttons.MwsModal.SetupWizardHavingTroubleArticle',
      PrivacyNote: 'Account.MwsSync.Buttons.MwsModal.PrivacyNote',
      HasSellerAccountResponseYes:
        'Account.MwsSync.Buttons.MwsModal.HasSellerAccountResponseYes',
      HasSellerAccountResponseNo:
        'Account.MwsSync.Buttons.MwsModal.HasSellerAccountResponseNo',
      BrandNewResponseYes:
        'Account.MwsSync.Buttons.MwsModal.BrandNewResponseYes',
      BrandNewResponseNo: 'Account.MwsSync.Buttons.MwsModal.BrandNewResponseNo'
    }
  },
  Dropdowns: {
    MwsModal: {
      MwsRegionNA: 'Account.MwsSync.Dropdowns.MwsModal.MwsRegionNA',
      MwsPrimaryMarketplaceCA:
        'Account.MwsSync.Dropdowns.MwsModal.MwsPrimaryMarketplaceCA',
      MwsPrimaryMarketplaceMX:
        'Account.MwsSync.Dropdowns.MwsModal.MwsPrimaryMarketplaceMX',
      MwsPrimaryMarketplaceUS:
        'Account.MwsSync.Dropdowns.MwsModal.MwsPrimaryMarketplaceUS',
      MwsRegionEU: 'Account.MwsSync.Dropdowns.MwsModal.MwsRegionEU',
      MwsRegionFE: 'Account.MwsSync.Dropdowns.MwsModal.MwsRegionFE',
      MwsPrimaryMarketplaceUK:
        'Account.MwsSync.Dropdowns.MwsModal.MwsPrimaryMarketplaceUK',
      MwsPrimaryMarketplaceDE:
        'Account.MwsSync.Dropdowns.MwsModal.MwsPrimaryMarketplaceDE',
      MwsPrimaryMarketplaceES:
        'Account.MwsSync.Dropdowns.MwsModal.MwsPrimaryMarketplaceES',
      MwsPrimaryMarketplaceFR:
        'Account.MwsSync.Dropdowns.MwsModal.MwsPrimaryMarketplaceFR',
      MwsPrimaryMarketplaceBE:
        'Account.MwsSync.Dropdowns.MwsModal.MwsPrimaryMarketplaceBE',
      MwsPrimaryMarketplaceIT:
        'Account.MwsSync.Dropdowns.MwsModal.MwsPrimaryMarketplaceIT',
      MwsPrimaryMarketplaceJP:
        'Account.MwsSync.Dropdowns.MwsModal.MwsPrimaryMarketplaceJP',
      MwsPrimaryMarketplaceAU:
        'Account.MwsSync.Dropdowns.MwsModal.MwsPrimaryMarketplaceAU',
      MwsPrimaryMarketplaceSG:
        'Account.MwsSync.Dropdowns.MwsModal.MwsPrimaryMarketplaceSG'
    }
  }
}
