import { FILTERS_DATA_ATTRIBUTE_IDS } from './filters'
import { ACTIONS_DATA_ATTRIBUTE_IDS } from './actions'
import { BUTTONS_DATA_ATTRIBUTE_IDS } from './buttons'
import { TABLE_DATA_ATTRIBUTE_IDS } from './table'
import { INPUTS_DATA_ATTRIBUTE_IDS } from './inputs'

export const INVENTORY_MANAGER_DATA_ATTRIBUTE_IDS = {
  Filters: FILTERS_DATA_ATTRIBUTE_IDS,
  Actions: ACTIONS_DATA_ATTRIBUTE_IDS,
  Buttons: BUTTONS_DATA_ATTRIBUTE_IDS,
  Table: TABLE_DATA_ATTRIBUTE_IDS,
  Inputs: INPUTS_DATA_ATTRIBUTE_IDS
}
